import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Ruleta from "./Views/Ruleta";
import React from "react";

import Error404 from "./Views/Error404";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route index path="/" element={<Ruleta />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
